<template>
    <a :href="'/crypto/tx/' + record.hash" class="item" v-if="record!==null">
        <div class="icon-box bg-success">
            <ion-icon :name="getIcon(record.action)"></ion-icon>
        </div>
        <div class="in">
            <div>
                <strong v-if="record.isToken">
                    [{{ record.action }}] {{ $root.getToken(record.to).name }}
                    {{ record.action == 'swap' ? ' x ' + $root.getToken(record.dst).name : '' }}
                </strong>
                <!-- <strong v-if="record.isToken">{{ record.to }}</strong> -->
                <!-- <strong>Bitcoin</strong> -->
                <div class="text-small text-secondary">Src: {{ record.src }}</div>
                <div class="text-small text-secondary">Dst: {{ record.dst }}</div>
            </div>
            <div class="text-end">
                <strong>{{ record.amount }} {{ $root.getToken(record.to).symbol }}</strong>
                <div class="text-small">
                    Hoy 11:38 AM
                </div>
            </div>
        </div>
    </a>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            default: null,
        }
    },
    methods: {
        getIcon(action) {
            switch (action) {
                case "transfer-out":
                    return "arrow-up-outline";
                    break;
                case "transfer-in":
                    return "arrow-down-outline";
                    break;
                default:
                    return "bug-outline";
                    break;
            }
        }
    }
}
</script>