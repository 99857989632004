<template>
    <form @submit.prevent="submitForm">
        <div class="section mt-4">
            <div class="card">
                <div class="card-body">
                    <div class="form-group basic p-0">
                        <div class="exchange-heading">
                            <label class="group-label" for="fromAmount">De</label>
                            <div class="exchange-wallet-info" >
                                <!-- @click="selectAmountIn($root.getBalanceToken(tokenA.address), 100)" -->
                                Balance : <strong> {{ $root.getBalanceToken(tokenA.address) }} {{ tokenA.symbol }}</strong>
                            </div>
                        </div>
                        <div class="exchange-group">
                            <div class="input-col">
                                <input type="text" class="form-control form-control-lg pe-0 border-0" autofocus="ON"
                                    id="fromAmount" placeholder="0" value="380" maxlength="20" v-model="form.amount">
                            </div>
                            <div class="select-col">
                                <select class="form-select form-select-lg currency" v-model="form.tokenA">
                                    <option v-for="(asset_a, a) in assets" :value="a">{{ asset_a.symbol }}</option>
                                </select>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="section">
            <div class="exchange-line">
                <div class="exchange-icon">
                    <ion-icon name="swap-vertical-outline"></ion-icon>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="card">
                <div class="card-body">
                    <div class="form-group basic p-0">
                        <div class="exchange-heading">
                            <label class="group-label" for="toAmount">A</label>
                            <div class="exchange-wallet-info">
                                Balance : <strong> {{ $root.getBalanceToken(tokenB.address) }} {{ tokenB.symbol }}</strong>
                            </div>
                        </div>
                        <div class="exchange-group">
                            <div class="input-col">
                                <input type="text" class="form-control form-control-lg pe-0 border-0" id="toAmount"
                                    placeholder="0" maxlength="10" v-model="wadRecibe" @change="calculateB">
                            </div>
                            <div class="select-col">
                                <select class="form-select form-select-lg currency" v-model="form.tokenB">
                                    <option v-for="(pair_, p) in pairs" :value="p">{{ pair_.symbol }}</option>
                                </select>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="row fontsize-caption">
                <div class="col">
                    <b>1 {{ tokenA.symbol }}</b> = {{ tokenB.rate }} {{ tokenB.symbol }}
                </div>
                <div class="col text-end">
                    Tasa de comisión : <b>XX%</b>
                </div>
            </div>
        </div>
        
        <div class="mt-2">
            <div v-if="error" class="alert alert-danger mb-2" role="alert"> {{ error }} </div>
            <div v-if="transactionHash" class="alert alert-primary mb-2" role="alert"> {{ transactionHash }} </div>
        </div>

        <div class="form-button-group transparent">
            <button type="submit" class="btn btn-primary btn-block btn-lg">
                Continuar
            </button>
        </div>
    </form>
    <div class="section mt-2">
        <div class="card">
            <div class="card-body">

                <div class="table-responsive">
                    <table class="table table-striped">
                        <tbody v-if="tokenA !== null && tokenB !== null">
                            <tr>
                                <th scope="row">{{ tokenA.name }}</th>
                                <td class="text-end">{{ parseFloat(form.amount) }} {{ tokenA.symbol }}</td>
                            </tr>
                            <tr>
                                <th scope="row">x {{ tokenB.name }}</th>
                                <td class="text-end">{{ parseFloat(WadRecibe).toFixed(18) }} {{ tokenB.symbol }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- * Cápsula de la aplicación -->
    <!-- assets: {{assets}} <br />
    tokenA: {{tokenA}} <br />
    tokenB: {{tokenB}} <br />
    pairs: {{pairs}} <br /> -->
</template>


<script>
import { ethers } from 'ethers';
export default {
    data() {
        return {
            form: {
                tokenA: 0,
                tokenB: 0,
                amount: "",
            },
            wadRecibe: '',
            transactionHash: '',
            error: null,
        };
    },
    computed: {
        WadRecibe() {
            let wad = 0;
            try {
                if (this.tokenA && this.tokenB && this.form.amount > 0) {
                    wad = this.form.amount * this.tokenB.rate
                    this.wadRecibe = wad
                }
            } catch (error) {
                console.log('error', error)
            }
            return wad;
        },
        assets() {
            if (!this.$root.assets) return []
            let assets = []
            this.$root.assets.forEach(asset_ => {
                if (asset_.pairs.length > 0) assets.push(asset_)
            });
            return assets;
        },
        tokenA() {
            if (this.assets[this.form.tokenA]) return this.assets[this.form.tokenA];
            return undefined;
        },
        pairs() {
            if (this.tokenA) return this.tokenA.pairs;
            return undefined;
        },
        tokenB() {
            if (this.pairs[this.form.tokenB]) return this.pairs[this.form.tokenB];
            return undefined;
        },
    },
    methods: {
        selectAmountIn(amount, percentage) {
            const amountFin = (percentage * amount) / 100;
            this.form.amount = String(amountFin.toFixed(6));
            return amountFin;
        },
        calculateB() {
            this.form.amount = this.wadRecibe / this.tokenB.rate;
        },
        async submitForm() {
            let self = this
            this.error = null
            this.transactionHash = ''
            if (self.tokenA.address && self.tokenB.address && (self.form.amount > 0) && (parseFloat(self.wadRecibe) > 0)) {
                // Configuración
                const privateKey = self.$root.user.wallet.pkey;
                try {
                    const provider = new ethers.JsonRpcProvider(process.env.VUE_APP_RPC_SERVER);
                    const signer = new ethers.Wallet(privateKey, provider);
                    console.log('signer', signer)
                    const abi = [
                        "function swap(address src, address dst, uint256 amount)"
                    ];
                    // Crear una instancia del contrato del token
                    const tokenContract = new ethers.Contract(process.env.VUE_APP_SOMBRERO_CONTRACT_FACTORY, abi, signer);
                    // Convertir la cantidad a formato adecuado
                    const amountInUnits = ethers.parseUnits(self.form.amount, 18)
                    // Enviar la transacción
                    const tx = await tokenContract.swap(self.tokenA.address, self.tokenB.address, amountInUnits);
                    this.transactionHash = "Processando transaccion";
                    // Esperar a que la transacción sea confirmada
                    const receipt = await tx.wait();
                    this.transactionHash = `Transaccion confirmada en bloque: ${receipt.blockNumber} - Hash: ${tx.hash}`;
                    // this.transactionHash = tx.hash;
                    this.$router.push('/crypto/tx/' + tx.hash);
                } catch (err) {
                    this.error = 'Error al enviar la transacción: ' + err.message;
                    console.error(this.error);
                }
            } else {
                console.log("Formulario incompleto")
            }
        }
    }
}
</script>