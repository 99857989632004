<template>
    <div class="appHeader no-border transparent position-absolute">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right"></div>
    </div>

    <div id="appCapsule">
        <div class="section mt-2 text-center">
            <h1>Respaldar Cuenta para verificarla</h1>
            <h4>Para iniciar, vamos a ingresar tu <strong>Frase mnemotécnica</strong></h4>
            <p>Es importante que tengas la frase secreta y el pin de esta wallet, de caso contrario no podemos acceder.</p>
        </div>

        <form @submit.prevent="submitForm">
            <div class="section mt-3">
                <div class="row" v-for="(row, rowIndex) in mnemonicRows" :key="rowIndex">
                    <div class="col-4" v-for="(input, inputIndex) in row" :key="inputIndex">
                        <div>
                            <div class="form-group boxed">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="mnemonic[rowIndex * 3 + inputIndex]"
                                    @paste="handlePaste"
                                    required 
                                    readonly
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="label" for="userid3">Tu dirección</label>
                        <input type="text" class="form-control" id="userid3" readonly v-bind:value="$root.user.wallet.address">
                        <i class="clear-input"><ion-icon name="close-circle"></ion-icon></i>
                    </div>
                    <div class="input-info">-----</div>
                </div>
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="label" for="userid3">Clave Privada (sin encriptar)</label>
                        <input type="text" class="form-control" id="userid3" readonly v-bind:value="$root.user.wallet.pkey">
                        <i class="clear-input"><ion-icon name="close-circle"></ion-icon></i>
                    </div>
                    <div class="input-info">Esta llave no debes</div>
                </div>

            </div>


            <div class="section mb-5 p-2">
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Verificar respaldo</button>
                </div>
            </div>
        </form>
    </div>
    <div class="modal fade dialogbox" id="DialogForm" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Verificar importacion</h5>
                </div>
                <form @submit.prevent="submitFormCreate">
                    <div class="modal-body text-start mb-2">

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <div class="form-group basic">
                                    <input type="password" class="form-control verification-input" placeholder="••••" maxlength="4" v-model="pin_verify" required>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <button type="button" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</button>
                            <button type="submit" class="btn btn-text-primary">CONFIRMAR</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- * Dialog Form -->
</template>

<script>
import { ethers, HDNodeWallet, deriveWalletFromSeed } from 'ethers';

import store from '@/store';
import { generateMnemonic, createWalletFromSeedWithPassword, verifyPrivateKeyAndGetAddress, importWalletFromSeed, importWalletFromSeedWithPassword, createWalletFromSeedWithPasswordAndPin } from '@/utils/web3';
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            mnemonic: Array(12).fill(""), // Array para almacenar las 12 palabras de la seed
            pin_verify: '',
        };
    },
    computed: {
        mnemonicRows() {
            // Dividir las entradas en 3 filas con 4 palabras cada una (o según la estructura)
            const rows = [];
            for (let i = 0; i < this.mnemonic.length; i += 3) {
                rows.push(this.mnemonic.slice(i, i + 3));
            }
            return rows;
        },
    },
    async mounted() {
        console.clear();
        this.mnemonic = localStorage.mnemonic.split(" ")
        console.log('mnemonic', this.mnemonic)
    },
    methods: {
        handlePaste(event) {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');

            // Dividimos la frase pegada por los espacios para obtener las palabras
            const words = pastedData.trim().split(/\s+/);

            // Solo llenamos si hay exactamente 12 palabras
            if (words.length === 12) {
                event.preventDefault(); // Prevenir que el valor se pegue en un solo campo
                this.mnemonic = words; // Asignamos las palabras a los campos correspondientes
            }
        },
        submitForm() {
            // Aquí puedes manejar el envío del formulario
            localStorage.removeItem('mnemonic')
            localStorage.removeItem('wallet')
            location.replace("/")
            // if (this.mnemonic) {
            //     var DialogForm = new Modal(document.getElementById('DialogForm'), {
            //         keyboard: false
            //     })
            //     DialogForm.show()
            // }
        },
        async submitFormCreate() {
            if (String(this.pin_verify).length==4) {
                const mnemonic = this.mnemonic.join(" ")
                const wallet = createWalletFromSeedWithPasswordAndPin(mnemonic, "", this.pin_verify)
                console.log('wallet', wallet)
                if (wallet && wallet.address) {
                    localStorage.setItem('wallet', wallet)
                    localStorage.setItem('pkey', wallet.privateKey)
                    await store.dispatch('loadWallet')
                    this.$router.push('/');
                    location.reload()
                }
            }
        }
    },
};
</script>

<style scoped>
/* Estilos adicionales si los necesitas */
</style>
