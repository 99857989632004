<template>
    <form @submit.prevent="submitForm">
        <div class="form-group basic mb-2">
            <div class="input-wrapper">
                <label class="label" for="walletaddress">Dirección de la billetera</label>
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="text" class="form-control" required placeholder="ID del usuario, Correo electronico o Direccion de la billetera" v-model="form.dst" autofocus="ON">
                        <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                        </i>
                    </div>
                    <div class="select-col">
                        <button type="button" class="btn btn-icon btn-outline-primary me-1" style="" data-bs-toggle="modal" data-bs-target="#DialogScanCode">
                            <ion-icon name="qr-code-outline" role="img" class="md hydrated" aria-label="newspaper outline"></ion-icon>
                        </button>
                    </div>
                </div>
                <!-- <input type="email" class="form-control" id="walletaddress" placeholder="Ingrese una dirección de billetera">
                <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                </i> -->
            </div>
        </div>
        <div class="form-group basic">
                <div class="exchange-heading">
                    <label class="group-label" for="fromAmount">Ingrese la cantidad (min/max pendiente)</label>
                    <div class="exchange-wallet-info">
                        Balance : <strong v-if="asset">{{ asset.balance }} {{ asset.symbol }}</strong>
                    </div>
                </div>
            <div class="input-wrapper">
                <!-- <label class="label" for="sendAmount">Ingrese la cantidad</label> -->
                <div class="exchange-group small">
                    <div class="input-col">
                        <input type="text" min="0.000000000000000001" step="0.000000000000000001" :max="asset.balance" class="form-control form-control-lg pe-0" placeholder="0" v-model="form.amount" required>
                    </div>
                    <div class="select-col">
                        <select class="form-select form-select-lg currency" v-model="form.token">
                            <!-- <option value="" selected>Seleccione</option> -->
                            <option v-for="(asset_a, a) in assets" :value="a">{{ asset_a.symbol }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2">
            <div v-if="error" class="alert alert-danger mb-2" role="alert"> {{ error }} </div>
            <div v-if="transactionHash" class="alert alert-primary mb-2" role="alert"> {{ transactionHash }} </div>
        </div>
        <div class="mt-2">
            <button type="submit" class="btn btn-primary btn-lg btn-block">Enviar</button>
        </div>
    </form>
    <div class="modal fade dialogbox" id="DialogScanCode" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div id="reader" style="width: 100%; height: 400px;"></div>
                <div class="modal-footer">
                    <div class="btn-inline">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getGasPrice } from '@/utils/web3';
import { ethers } from 'ethers';
export default {
    data() {
        return {
            html5QrCode: null,
            form: {
                dst: "",
                token: 0,
                amount: "",
            },
            transactionHash: '',
            error: null,
        };
    },
    computed: {
        assets() {
            if (!this.$root.assets) return []
            let assets = []
            this.$root.assets.forEach(asset_ => {
                if (asset_.pairs.length > 0) assets.push(asset_)
            });
            return this.$root.assets;
        },
        asset() {
            if (this.assets[this.form.token]) return this.assets[this.form.token];
            return undefined;
        },
    },
    methods: {
        async submitForm() {
            let self = this
            console.log('submit', this.form)
            this.error = null
            this.transactionHash = ''
            // Configuración
            const privateKey = self.$root.user.wallet.pkey;
            const tokenAddress = self.asset.address;
            try {
                // Crear el proveedor y el signer
                const provider = new ethers.JsonRpcProvider(process.env.VUE_APP_RPC_SERVER);
                const signer = new ethers.Wallet(privateKey, provider);
                console.log('signer', signer)
                // ABI simplificado para el método `transfer`
                const abi = [
                    "function transfer(address to, uint256 amount) public returns (bool)"
                ];
                // Crear una instancia del contrato del token
                const tokenContract = new ethers.Contract(tokenAddress, abi, signer);
                // Convertir la cantidad a formato adecuado
                const amountInUnits = ethers.parseUnits(this.form.amount, 18); // Ajusta los decimales si es necesario
                console.log('amountInUnits', amountInUnits)
                // Opciones de transacción
                const options = {
                    gasLimit: ethers.toNumber('500000'), // Puedes ajustar el gas limit según lo que necesites
                    // gasPrice: ethers.toNumber('1'), // Puedes ajustar el gas limit según lo que necesites
                    // gasPrice: ethers.toNumber(await getGasPrice()) // Puedes usar un precio de gas personalizado o obtenerlo dinámicamente del proveedor
                };
                console.log('options', options)
                
                // Enviar la transacción con las opciones de gas
                const tx = await tokenContract.transfer(self.form.dst, amountInUnits, options);
                this.transactionHash = "Procesando transaccion...";

                // Esperar a que la transacción sea confirmada
                const receipt = await tx.wait();
                this.transactionHash = `Transacción confirmada en bloque :${receipt.blockNumber} - Hash: ${tx.hash}`;
                this.$router.push('/crypto/tx/' + tx.hash);
            } catch (err) {
                this.error = 'Error al enviar la transacción: ' + err.message;
                console.error(this.error);
            }
        }
    }
}
</script>