<template>
    <!-- Barra lateral de la aplicación -->
    <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <!-- cuadro de perfil -->
                    <div class="profileBox pt-2 pb-2">
                        <div class="image-wrapper">
                            <div v-html="this.$root.user.avatar" class="imaged  w36"></div>
                            <!-- <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged  w36"> -->
                        </div>
                        <div class="in">
                            <strong>{{ $root.user.name }}</strong>
                            <div class="text-muted" v-if="!$root.user.isUser && !$root.user.isVerified">Sin registro</div>
                            <div class="text-muted" v-else-if="$root.user.isUser && !$root.user.isVerified">No Verificado</div>
                            <div class="text-muted" v-else-if="$root.user.isUser && $root.user.isVerified">Verificado</div>
                        </div>
                        <a href="#" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                            <ion-icon name="close-outline"></ion-icon>
                        </a>
                    </div>
                    <!-- * cuadro de perfil -->
                    <!-- saldo -->
                    <div class="sidebar-balance">
                        <div class="listview-title">Saldo</div>
                        <div class="in">
                            <h1 class="amount">{{ $root.totalCoinDefault }} {{ $root.getToken($root.user.token_default).symbol }}</h1>
                        </div>
                    </div>
                    <!-- * saldo -->

                    <!-- grupo de acción -->
                    <!-- <div class="action-group">
                        <a href="#" class="action-button" data-bs-toggle="modal" data-bs-target="#DialogImaged">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="qr-code"></ion-icon>
                                </div>
                                Recibir
                            </div>
                        </a>
                        <router-link to="/send" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="arrow-down-outline"></ion-icon>
                                </div>
                                Enviar
                            </div>
                        </router-link>
                        <router-link to="/swap" class="action-button" data-bs-dismiss="modal">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                </div>
                                Enviar
                            </div>
                        </router-link>
                        <a href="app-cards.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                Mis tarjetas
                            </div>
                        </a>
                    </div> -->
                    <!-- * grupo de acción -->

                    <!-- menú -->
                    <div class="listview-title mt-1">Menú</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="/" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="pie-chart-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Visión general
                                    <span class="badge badge-primary">10</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/verify/kyc" class="item" v-if="requiredKyc">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="document-text-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    KYC
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/verify/wallet" class="item" v-if="requiredBackup">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="document-text-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Respaldar wallet
                                </div>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="app-cards.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Mis tarjetas
                                </div>
                            </a>
                        </li> -->
                    </ul>
                    <!-- * menú -->

                    <!-- otros -->
                    <div class="listview-title mt-1">Otros</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="/settings" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="settings-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Configuración
                                </div>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="component-messages.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="chatbubble-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Soporte
                                </div>
                            </a>
                        </li> -->
                        <li>
                            <a href="/logout" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="log-out-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Cerrar sesión
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- * otros -->

                </div>
            </div>
        </div>
    </div>
    <!-- * Barra lateral de la aplicación -->
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios'
import bootstrap from 'bootstrap'
export default {
  name: 'AppSidebar',
  computed: {
    requiredKyc() {
        return !this.$root.user.isVerified;
    },
    requiredBackup() {
        return (localStorage.mnemonic) ?? false;
    },
    // ...mapGetters({ 'currentInfo': 'StateUser' }),
  },
  mounted() {
    const Finapp = {
        RTL: {
            enable: false, // Enable or disable RTL Mode
        },
    }
    var appSidebar = document.getElementById("sidebarPanel")
    //-----------------------------------------------------------------------
    // RTL (Right to Left)
    if (Finapp.RTL.enable) {
        var pageHTML = document.querySelector("html")
        pageHTML.dir = "rtl"
        document.querySelector("body").classList.add("rtl-mode")
        if (appSidebar != null) {
            appSidebar.classList.remove("panelbox-left")
            appSidebar.classList.add("panelbox-right")
        }
        document.querySelectorAll(".carousel-full, .carousel-single, .carousel-multiple, .carousel-small, .carousel-slider").forEach(function (el) {
            el.setAttribute('data-splide', '{"direction":"rtl"}')
        })
    }
    //-----------------------------------------------------------------------
    const modal = document.getElementById("sidebarPanel");
    
    modal.addEventListener('hide.bs.modal', () => {
      console.log('hide')  
    })
    modal.addEventListener('hidden.bs.modal', () => {
      console.log('hidden')
    })
    modal.addEventListener('hidePrevented.bs.modal', () => {
      console.log('hidePrevented')  
    })
    modal.addEventListener('shown.bs.modal', () => {
        
    })
  },
  methods: {
    logOut() {
        const modal = document.getElementById("sidebarPanel");
        let self = this
        console.log('logOut')
        axios.post('logout')
        .then(response => {
            console.log('response', response)
            if (response.status == 200) {
                // modal.close()
                // self.$router.push('/login')
                location.replace('/')
            }
        })
        .catch(e => console.log(e))
    },
    closeSidebar() {
        const modal = document.getElementById("sidebarPanel");
    },
  },
}
</script>
