import axios from 'axios';
import store from '@/store'
import { compileAsync } from 'sass'

import { createRouter, createWebHistory } from 'vue-router'

import AppLayout from '../layouts/AppLayout.vue'
import CryptoLayout from '../layouts/CryptoLayout.vue'

import AppHomeView from '../views/app/HomeView.vue'

import AppComponentsView from '../views/app/ComponentsView.vue'
import AppComponentsAccordionView from '../views/app/components/AccordionView.vue'
import AppComponentsActionSheetView from '../views/app/components/ActionSheetView.vue'
import AppComponentsAddToHomeView from '../views/app/components/AddToHomeView.vue'
import AppComponentsAlertView from '../views/app/components/AlertView.vue'
import AppComponentsAppbottommenuView from '../views/app/components/AppbottommenuView.vue'
import AppComponentsAppheaderView from '../views/app/components/AppheaderView.vue'
import AppComponentsBadgeView from '../views/app/components/BadgeView.vue'
import AppComponentsButtonView from '../views/app/components/ButtonView.vue'
import AppComponentsCardView from '../views/app/components/CardView.vue'
import AppComponentsCarouselView from '../views/app/components/CarouselView.vue'
import AppComponentsChartsView from '../views/app/components/ChartsView.vue'
import AppComponentsCheckboxView from '../views/app/components/CheckboxView.vue'
import AppComponentsChipsView from '../views/app/components/AppComponentsChipsView.vue'
import AppComponentsContentboxView from '../views/app/components/ContentboxView.vue'
import AppComponentsDialogView from '../views/app/components/DialogView.vue'
import AppComponentsDropdownView from '../views/app/components/DropdownView.vue'
import AppComponentsFullpageSliderView from '../views/app/components/FullpageSliderView.vue'
import AppComponentsGridView from '../views/app/components/GridView.vue'
import AppComponentsIconsView from '../views/app/components/IconsView.vue'
import AppComponentsImagesView from '../views/app/components/ImagesView.vue'
import AppComponentsInputsView from '../views/app/components/InputsView.vue'
import AppComponentsListviewView from '../views/app/components/ListviewView.vue'
import AppComponentsMessagesView from '../views/app/components/MessagesView.vue'
import AppComponentsModalAndPanelsView from '../views/app/components/ModalAndPanelsView.vue'
import AppComponentsNotificationView from '../views/app/components/NotificationView.vue'
import AppComponentsPreloaderView from '../views/app/components/PreloaderView.vue'
import AppComponentsProgressbarView from '../views/app/components/ProgressbarView.vue'
import AppComponentsRadioView from '../views/app/components/RadioView.vue'
import AppComponentsSearchView from '../views/app/components/SearchView.vue'
import AppComponentsTableView from '../views/app/components/TableView.vue'
import AppComponentsTabsView from '../views/app/components/TabsView.vue'
import AppComponentsTimelineView from '../views/app/components/TimelineView.vue'
import AppComponentsToastView from '../views/app/components/ToastView.vue'
import AppComponentsToggleView from '../views/app/components/ToggleView.vue'
import AppComponentsTooltipsView from '../views/app/components/TooltipsView.vue'
import AppComponentsTypographyView from '../views/app/components/TypographyView.vue'

import VerifyKycView from '../views/verify/KycView.vue'
import VerifyWalletView from '../views/verify/WalletView.vue'

import AccountSettingsView from '../views/account/SettingsView.vue'

import AuthHomeView from '../views/auth/HomeView.vue'
import AuthCreatewalletView from '../views/auth/CreatewalletView.vue'

import AuthLoginWalletView from '../views/auth/LoginWalletView.vue'
import AuthLoginPrivatekeyView from '../views/auth/LoginPrivatekeyView.vue'
import AuthLoginMnemonicView from '../views/auth/LoginMnemonicView.vue'

import CryptoLogoutView from '../views/crypto/LogoutView.vue'
import CryptoTransactionsView from '../views/crypto/TransactionsView.vue'

import CryptoHomeView from '../views/crypto/HomeView.vue'
import CryptoPortfolioView from '../views/crypto/PortfolioView.vue'
import CryptoSendView from '../views/crypto/SendView.vue'
import CryptoSwapView from '../views/crypto/SwapView.vue'
import CryptoP2PExchangeView from '../views/crypto/P2PExchangeView.vue'
import CryptoTokenView from '../views/crypto/TokenView.vue'
import CryptoTxView from '../views/crypto/TxView.vue'

const routes = [
  {
    path: process.env.BASE_URL + '/',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'app-home',
        component: CryptoHomeView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'logout',
        name: 'app-logout',
        component: CryptoLogoutView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'settings',
        name: 'app-settings',
        component: AccountSettingsView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'auth',
        name: 'auth-home',
        component: AuthHomeView,
        meta: { guest: true, },
      },
      {
        path: 'auth/login',
        name: 'auth-login-wallet',
        component: AuthLoginWalletView,
        meta: { guest: true, },
      },
      {
        path: 'auth/login/privatekey',
        name: 'auth-login-privatekey',
        component: AuthLoginPrivatekeyView,
        meta: { guest: true, },
      },
      {
        path: 'auth/login/mnemonic',
        name: 'auth-login-mnemonic',
        component: AuthLoginMnemonicView,
        meta: { guest: true, },
      },
      {
        path: 'auth/create-wallet',
        name: 'auth-create-wallet',
        component: AuthCreatewalletView,
        meta: { guest: true, },
      },
      {
        path: 'verify/kyc',
        name: 'app-verify-kyc',
        component: VerifyKycView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'verify/wallet',
        name: 'app-verify-wallet',
        component: VerifyWalletView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'send',
        name: 'app-send',
        component: CryptoSendView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'swap',
        name: 'app-swap',
        component: CryptoSwapView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'p2p-exchange',
        name: 'app-p2p-exchange',
        component: CryptoP2PExchangeView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'crypto/portfolio',
        name: 'crypto-portfolio',
        component: CryptoPortfolioView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'crypto/token/:address',
        name: 'crypto-token',
        component: CryptoTokenView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'crypto/transactions',
        name: 'crypto-transactions',
        component: CryptoTransactionsView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'crypto/tx/:hash',
        name: 'crypto-tx',
        component: CryptoTxView,
        meta: { requiresAuth: true, },
      },
      {
        path: 'components',
        name: 'app-components',
        component: AppComponentsView,
      },
      { path: 'components/accordion', name: 'app-components-accordion', component: AppComponentsAccordionView, },
      { path: 'components/action-sheet', name: 'app-components-action-sheet', component: AppComponentsActionSheetView, },
      { path: 'components/add-to-home', name: 'app-components-add-to-home', component: AppComponentsAddToHomeView, },
      { path: 'components/alert', name: 'app-components-alert', component: AppComponentsAlertView, },
      { path: 'components/appbottommenu', name: 'app-components-appbottommenu', component: AppComponentsAppbottommenuView, },
      { path: 'components/appheader', name: 'app-components-appheader', component: AppComponentsAppheaderView, },
      { path: 'components/badge', name: 'app-components-badge', component: AppComponentsBadgeView, },
      { path: 'components/button', name: 'app-components-button', component: AppComponentsButtonView, },
      { path: 'components/card', name: 'app-components-card', component: AppComponentsCardView, },
      { path: 'components/carousel', name: 'app-components-carousel', component: AppComponentsCarouselView, },
      { path: 'components/charts', name: 'app-components-charts', component: AppComponentsChartsView, },
      { path: 'components/checkbox', name: 'app-components-checkbox', component: AppComponentsCheckboxView, },
      { path: 'components/chips', name: 'app-components-chips', component: AppComponentsChipsView, },
      { path: 'components/contentbox', name: 'app-components-contentbox', component: AppComponentsContentboxView, },
      { path: 'components/dialog', name: 'app-components-dialog', component: AppComponentsDialogView, },
      { path: 'components/dropdown', name: 'app-components-dropdown', component: AppComponentsDropdownView, },
      { path: 'components/fullpage-slider', name: 'app-components-fullpage-slider', component: AppComponentsFullpageSliderView, },
      { path: 'components/grid', name: 'app-components-grid', component: AppComponentsGridView, },
      { path: 'components/icons', name: 'app-components-icons', component: AppComponentsIconsView, },
      { path: 'components/images', name: 'app-components-images', component: AppComponentsImagesView, },
      { path: 'components/inputs', name: 'app-components-inputs', component: AppComponentsInputsView, },
      { path: 'components/listview', name: 'app-components-listview', component: AppComponentsListviewView, },
      { path: 'components/messages', name: 'app-components-messages', component: AppComponentsMessagesView, },
      { path: 'components/modal-and-panels', name: 'app-components-modal-and-panels', component: AppComponentsModalAndPanelsView, },
      { path: 'components/notification', name: 'app-components-notification', component: AppComponentsNotificationView, },
      { path: 'components/preloader', name: 'app-components-preloader', component: AppComponentsPreloaderView, },
      { path: 'components/progressbar', name: 'app-components-progressbar', component: AppComponentsProgressbarView, },
      { path: 'components/radio', name: 'app-components-radio', component: AppComponentsRadioView, },
      { path: 'components/search', name: 'app-components-search', component: AppComponentsSearchView, },
      { path: 'components/table', name: 'app-components-table', component: AppComponentsTableView, },
      { path: 'components/tabs', name: 'app-components-tabs', component: AppComponentsTabsView, },
      { path: 'components/timeline', name: 'app-components-timeline', component: AppComponentsTimelineView, },
      { path: 'components/toast', name: 'app-components-toast', component: AppComponentsToastView, },
      { path: 'components/toggle', name: 'app-components-toggle', component: AppComponentsToggleView, },
      { path: 'components/tooltips', name: 'app-components-tooltips', component: AppComponentsTooltipsView, },
      { path: 'components/typography', name: 'app-components-typography', component: AppComponentsTypographyView, },
      // { path: 'components/', name: 'app-components-', component: AppComponents View, },
    ]
  },
  {
    path: process.env.BASE_URL + '/crypto',
    component: CryptoLayout,
    children: [
      // {
      //   path: '',
      //   name: 'crypto-home',
      //   component: CryptoHomeView,
      // },
    ]
  },
]

console.log('Creando Router')
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
