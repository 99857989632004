<template>
    <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
        <!-- item -->
        <li v-for="(record, i) in records">
            <crypto-transactions-list-item :record="record" />
        </li>
        <!-- * item -->
        <!-- <li>
            <a href="#" class="item">
                <div class="icon-box bg-warning">
                    <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <div class="in">
                    <div>
                        <strong>Etherium</strong>
                        <div class="text-small text-secondary">Comprar</div>
                    </div>
                    <div class="text-end">
                        <strong>$40,68</strong>
                        <div class="text-small">
                            8 de marzo de 2021
                        </div>
                    </div>
                </div>
            </a>
        </li> -->
    </ul>
</template>

<script>
import { ethers } from 'ethers'
import { getLatestBlock } from "@/utils/web3"

export default {
props: {
    size: {
        type: Number,
        default: 4,
    },
    address: {
        type: String,
        default: '0x0',
    }
},
data() {
    return {
        records: []
    };
},
async mounted() {
    await this.loadTransactions()
},
methods: {
    async loadTransactions() {
        // console.clear()
        let self = this
        this.records = []
        try {
            let walletAddress = self.address
            // console.log('walletAddress', walletAddress)
            const provider = new ethers.JsonRpcProvider(process.env.VUE_APP_RPC_SERVER);
            // Obtener el último bloque
            const latestBlockNumber = await provider.getBlockNumber();
            // const latestBlockNumber = 10;
            // Definir cuántos bloques atrás deseas explorar (en este caso, los últimos 10 bloques)
            let numberOfBlocksToCheck = 1;
            // Recorrer los bloques recientes
            for (let i = latestBlockNumber; i >= latestBlockNumber - numberOfBlocksToCheck; i--) {
                // console.log('i', i)
                const block = await provider.getBlock(i);
                const erc20Abi = [
                    "function transfer(address dst, uint wad)",
                    "function transferFrom(address src, address dst, uint wad)",
                    "function withdrawExternal(address dst, address asset, uint wad)",
                    "function withdrawTo(uint wad, address dst)",
                    "function withdraw(uint wad)",
                    "function depositExternal(address dst, address asset, uint wad)",
                    "function depositTo(address dst)",
                    "function deposit()",
                    "function swap(address src, address dst, uint256 amount)",
                ];
                // Crear una instancia de la interfaz usando el ABI
                const iface = new ethers.Interface(erc20Abi);
                block.transactions.forEach(async (txHash) => {
                    let itemTx = {}
                    let src = null
                    let dst = null
                    let wad = 0
                    let amount = 0
                    let isToken = false
                    let action = 'none'
                    let tx = await provider.getTransaction(txHash)
                    const contractsAddress = self.$root.assets.map(a => {
                        return a.address;
                    })
                    // console.log('contractsAddress', contractsAddress)
                    // console.log('tx', txHash, tx)
                    // console.log('from', tx.from)
                    // console.log('to', tx.to)

                    tx.functionSelector = tx.data.slice(0, 10); // El 0x + 8 caracteres = 10 caracteres
                    tx.functionFragment = iface.getFunction(tx.functionSelector);
                    
                    if (tx.to == process.env.VUE_APP_SOMBRERO_CONTRACT_FACTORY) {
                        // console.log('VUE_APP_SOMBRERO_CONTRACT_FACTORY')
                        if (tx.functionSelector == '0x' || tx.functionFragment == null) {} // Moneda/Funciones nativas
                        else {
                            tx.decodedData = iface.decodeFunctionData(tx.functionFragment.name, tx.data);
                            // console.log(`Función llamada: ${tx.functionFragment.name}`);
                            if (tx.functionFragment.name === "swap") {
                                isToken = true
                                // console.log('swap', tx.from, tx.decodedData.dst, tx.decodedData.amount)
                                tx.to = tx.decodedData.src
                                src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.amount, amount = ethers.formatEther(tx.decodedData.amount)
                                action = 'swap'
                            }
                        }
                    }
                    else if (contractsAddress.includes(tx.to)) {
                        isToken = true
                        // console.log('Asset')
                        if (tx.functionSelector == '0x' || tx.functionFragment == null) {} // Moneda/Funciones nativas
                        else {
                            tx.decodedData = iface.decodeFunctionData(tx.functionFragment.name, tx.data);
                            // console.log(`Función llamada: ${tx.functionFragment.name}`);
                            if (tx.functionFragment.name === "transfer") {
                                // console.log('transfer', tx.from, tx.decodedData.dst, tx.decodedData.wad)
                                src = tx.from, dst = tx.decodedData.dst, wad = tx.decodedData.wad, amount = ethers.formatEther(tx.decodedData.wad)
                                if (src.toLowerCase() === walletAddress.toLowerCase()) action = 'transfer-out'
                                else if (dst.toLowerCase() === walletAddress.toLowerCase()) action = 'transfer-in'
                            }
                        }
                    }
                    if ((src && src.toLowerCase() === walletAddress.toLowerCase()) || (dst && dst.toLowerCase() === walletAddress.toLowerCase())) { // PARA TOKENS
                        // console.log("Anexando:", tx)
                        tx.src = src, tx.dst = dst, tx.wad = wad, tx.amount = amount, tx.isToken = isToken, tx.action = action
                        this.records.push(tx);
                    }
                    if (this.records.length < self.size-1 && numberOfBlocksToCheck <= latestBlockNumber) numberOfBlocksToCheck++;
                });

            }
            if (this.records.length === 0) {
            console.log("No se encontraron transacciones para la dirección proporcionada.");
            }
        } catch (error) {
            console.error("Error al obtener las transacciones:", error);
        }
    }
}
}
</script>