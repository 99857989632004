<template>
    <!-- Formulario de registro del usuario -->
    <form @submit.prevent="registerUser">
        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="userid3">Nombre completo</label>
                <input type="text" class="form-control" id="userid3" placeholder="Ingresa tu nombre completo" v-model="name">
                <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                </i>
            </div>
            <div class="input-info">Por favor ingresa tu nombre completo como aparece en el documento de identidad</div>
        </div>
        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="userid3">Document Hash (CID)</label>
                <input type="text" class="form-control" id="userid3" placeholder="0x0" v-model="documentHash">
                <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                </i>
            </div>
            <div class="input-info">CID del documento de identidad</div>
        </div>
        <div class="form-group basic">
            <div class="input-wrapper">
                <label class="label" for="userid3">Residence Proof Hash (CID)</label>
                <input type="text" class="form-control" id="userid3" placeholder="0x0" v-model="residenceProofHash">
                <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                </i>
            </div>
            <div class="input-info">Por favor ingresa</div>
        </div>
        
        <div class="mt-2">
            <div v-if="$root.user.isUser && !$root.user.isVerified" class="alert alert-warning mb-2" role="alert"> Ya haz enviado una verificacion debes esperar que sea validada. </div>
            <div v-if="error" class="alert alert-danger mb-2" role="alert"> {{ error }} </div>
            <div v-if="txHash" class="alert alert-primary mb-2" role="alert"> {{ txHash }} </div>
        </div>
        <div class="mt-2">
            <button type="submit" class="btn btn-primary btn-lg btn-block">Enviar</button>
        </div>
    </form>

</template>

<script>
import { ethers } from 'ethers';

export default {
  data() {
    return {
      name: '',
      documentHash: '',
      residenceProofHash: '',
      txHash: null,
      error: null,
      loading: false
    };
  },
  methods: {
    async registerUser() {
        let self = this
      this.loading = true;
      this.error = null;

      try {
        // Configura el proveedor utilizando el RPC server desde las variables de entorno
        const provider = new ethers.JsonRpcProvider(process.env.VUE_APP_RPC_SERVER);
        
        // Configura el signer (se asume que el usuario tiene MetaMask o similar)
        // const signer = await provider.getSigner();
            const privateKey = self.$root.user.wallet.pkey;
        const signer = new ethers.Wallet(privateKey, provider);

        // Dirección del contrato y ABI (Asegúrate de que esto sea correcto)
        const contractAddress = process.env.VUE_APP_SOMBRERO_CONTRACT_KYC;
        const abi = [
          // ABI del contrato KYC (solo se muestran las funciones necesarias)
          "function registerUser(string memory _name, string memory _documentHash, string memory _residenceProofHash) public"
        ];

        // Crea una instancia del contrato
        const contract = new ethers.Contract(contractAddress, abi, signer);

        // Interactúa con el contrato enviando la transacción
        const tx = await contract.registerUser(this.name, this.documentHash, this.residenceProofHash);
        this.txHash = tx.hash;

        // Espera la confirmación de la transacción
        await tx.wait();
        
        this.loading = false;
        alert('User registered successfully!');
      } catch (err) {
        this.error = err.message;
        this.loading = false;
      }
    }
  }
};
</script>
