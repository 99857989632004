import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/css/style.css"
import "./assets/js/plugins/splide/splide.min.js"

import AppBottomMenu from "./components/app/BottomMenu.vue"
// import AppHeader from "./components/app/Header.vue"
import AppSidebar from "./components/app/Sidebar.vue"
import AppFooter from "./components/app/Footer.vue"

// Components
import LoaderBox from "./components/LoaderBox.vue"
import Accordion from "./components/Accordion.vue"
import AccordionItem from "./components/AccordionItem.vue"

import sectionWallet from "./components/crypto/sectionWallet.vue"
import sectionPortfolio from "./components/crypto/sectionPortfolio.vue"
import PortfolioList from "./components/crypto/PortfolioList.vue"
import PortfolioListItem from "./components/crypto/PortfolioListItem.vue"
import sectionWatchlist from "./components/crypto/sectionWatchlist.vue"
import sectionTransactions from "./components/crypto/sectionTransactions.vue"
import sectionPromo from "./components/crypto/sectionPromo.vue"
import sectionNews from "./components/crypto/sectionNews.vue"
import QrCodeAddress from "./components/crypto/qr-code-address.vue"

import CryptoTransactionsList from "./components/crypto/TransactionsList.vue"
import CryptoTransactionsListItem from "./components/crypto/TransactionsListItem.vue"
import CryptoTokenHistoryList from "./components/crypto/TokenHistoryList.vue"

import FormSend from "./components/form/FormSend.vue"
import FormSwap from "./components/form/FormSwap.vue"
import FormVerifyKyc from "./components/form/FormVerifyKyc.vue"

axios.defaults.withCredentials = true
axios.defaults.baseURL = '/api.php';

/**
 * Crear Touter con wallet
 */
console.log('Creando validacion de Wallet')
await store.dispatch('loadWallet')
router.beforeEach(async (to, from, next) => {
  console.log('validando session para router')
  console.log('isAuthenticated', store.getters.isAuthenticated)
  console.log('Start')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log('ReqAuth...')
    if (store.getters.isAuthenticated) {
      console.log('Conectado...')
      next()
    } else {
      console.log('No Conectado...')
      next({
        path: '/auth',
        query: { redirect: to.fullPath }
      })
    }
  } 
  else if (to.matched.some(record => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      console.log('Conectado...')
      next({
        path: '/',
        query: { origin: to.fullPath }
      })
    } else {
      console.log('No Conectado...')
      next()
    }
  }
  else {
    next()
  }
  console.log('End')
})

const app = createApp(App)

app.use(store)
app.use(router)

app.component('LoaderBox', LoaderBox)
// app.component('AppHeader', AppHeader)
app.component('app-sidebar', AppSidebar)
app.component('app-footer', AppFooter)
app.component('app-menu-bottom', AppBottomMenu)

app.component('accordion', Accordion)
app.component('accordion-item', AccordionItem)
app.component('section-wallet', sectionWallet)
app.component('section-portfolio', sectionPortfolio)
app.component('portfolio-list-item', PortfolioListItem)
app.component('portfolio-list', PortfolioList)
app.component('section-watchlist', sectionWatchlist)
app.component('section-transactions', sectionTransactions)
app.component('section-promo', sectionPromo)
app.component('qr-code-address', QrCodeAddress)

app.component('form-send', FormSend)
app.component('form-swap', FormSwap)
app.component('form-verify-kyc', FormVerifyKyc)

app.component('crypto-transactions-list', CryptoTransactionsList)
app.component('crypto-transactions-list-item', CryptoTransactionsListItem)
app.component('crypto-token-history-list', CryptoTokenHistoryList)

app.mount("#app")