<template>
    <template v-if="record !== null">

        <!-- Encabezado de la aplicación -->
        <div class="appHeader bg-primary text-light">
            <div class="left">
                <a href="/" class="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div class="pageTitle">
                {{ record.name }} ({{ record.symbol }})
            </div>
            <div class="right">
                <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#actionSheetShare">
                    <ion-icon name="share-social-outline"></ion-icon>
                </a>
            </div>
        </div>
        <!-- * Encabezado de la aplicación -->
    
    
        <!-- Hoja de acción para compartir -->
        <div class="modal fade action-sheet inset" id="actionSheetShare" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Compartir con</h5>
                    </div>
                    <div class="modal-body">
                        <ul class="action-button-list">
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="logo-facebook"></ion-icon>
                                        Facebook
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="logo-twitter"></ion-icon>
                                        Twitter / X
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="logo-instagram"></ion-icon>
                                        Instagram
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="logo-linkedin"></ion-icon>
                                        Linkedin
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Hoja de acción para compartir -->
    
        <!-- Cápsula de la aplicación -->
        <div id="appCapsule">
            <!-- Estado de la moneda -->
            <div class="section full gradientSection">
                <div class="in coin-head">
                    <h1 class="total">{{ record.balance }} {{ record.symbol }}</h1>
                    <h4 class="caption">
                        <span class="iconbox text-success">
                            <ion-icon name="caret-up"></ion-icon>
                        </span>
                        {{ $root.getCalcFromRate(record.balance, $root.getPairUser(record.address)) }} <strong>{{ $root.user.coin.symbol }}</strong>
                    </h4>
                </div>
            </div>
            <!-- * Estado de la moneda -->
    
    
            <!-- Gráfico de la moneda -->
            <div class="section mb-2">
                <div class="card coin-chart">
                    <!-- <div class="card-body pt-1">
                        <ul class="nav nav-tabs lined" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#tab-24h" role="tab">
                                    24H
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tab-1w" role="tab">
                                    1S
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tab-1m" role="tab">
                                    1M
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tab-1y" role="tab">
                                    1A
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#tab-all" role="tab">
                                    Todo
                                </a>
                            </li>
                        </ul>
                    </div> -->
    
                    <!-- <div class="tab-content">
                        <div class="tab-pane fade show active" id="tab-24h" role="tabpanel">
                            <div class="chart-example-1"></div>
                        </div>
    
                        <div class="tab-pane fade" id="tab-1w" role="tabpanel">
                            <div class="chart-example-2"></div>
                        </div>
                        <div class="tab-pane fade" id="tab-1m" role="tabpanel">
                            <div class="chart-example-3"></div>
                        </div>
                        <div class="tab-pane fade" id="tab-1y" role="tabpanel">
                            <div class="chart-example-4"></div>
                        </div>
                        <div class="tab-pane fade" id="tab-all" role="tabpanel">
                            <div class="chart-example-5"></div>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- Gráfico de la moneda -->
    
    
            <!-- Botones -->
            <!-- <div class="section mt-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <a href="#" class="btn btn-block btn-lg btn-success">COMPRAR</a>
                            </div>
                            <div class="col">
                                <a href="/swap" class="btn btn-block btn-lg btn-secondary">INTERCAMBIO</a>
                            </div>
                            <div class="col">
                                <a href="#" class="btn btn-block btn-lg btn-secondary">VENDER</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- Botones -->
    
            <!-- Estadísticas -->
            <div class="section mt-2 mb-4">
                <div class="card">
                    <ul class="listview no-line transparent flush simple-listview">
                        <li>
                            <div class="text-muted">Address Token</div>
                            <strong :class="isTokenOfficial ? 'text-success' : 'text-warning'">{{ record.owner }}</strong>
                        </li>
                        <li>
                            <div class="text-muted">Nombre</div>
                            <strong>{{ record.name }}</strong>
                        </li>
                        <li>
                            <div class="text-muted">Simbolo</div>
                            <strong>{{ record.symbol }}</strong>
                        </li>
                        <li>
                            <div class="text-muted">Decimales</div>
                            <strong>{{ record.decimals }}</strong>
                        </li>
                        <li>
                            <div class="text-muted">Unidad Minima</div>
                            <strong>{{ (1 / 1e18).toFixed(record.decimals) }}</strong>
                        </li>
                        <li>
                            <div class="text-muted">Circulado dentro de Sombrero</div>
                            <strong>{{ record.totalSupply }}</strong>
                        </li>
                    </ul>
                </div>
                <!-- <div class="card mt-2">
                    <div class="card-body">
                        <div class="row mb-05 fontsize-sub">
                            <div class="col text-success"><strong>72% Comprar</strong></div>
                            <div class="col text-secondary text-end"><strong>26% Vender</strong></div>
                        </div>
                        <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 72%" aria-valuenow="72"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-secondary" role="progressbar" style="width: 28%" aria-valuenow="28"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- Estadísticas -->
    
            <!-- Historial -->
            <div class="section mt-4">
                <div class="section-heading">
                    <h2 class="title">Historial</h2>
                    <a href="#" class="link">Ver todo</a>
                </div>
                <div class="card">
                    <crypto-token-history-list :address="record.address" size="6" />

                    <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                        <!-- item -->
                        <li>
                            <a href="#" class="item">
                                <div class="icon-box bg-success">
                                    <ion-icon name="arrow-up-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    <div>
                                        <strong>Bitcoin</strong>
                                        <div class="text-small text-secondary">Orden de venta #7893</div>
                                    </div>
                                    <div class="text-end">
                                        <strong>$855,24</strong>
                                        <div class="text-small">
                                            Hoy 11:38 AM
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <!-- * item -->
                    </ul>
                    <div class="card-body pt-0">
                        <a href="#" class="btn btn-block btn-outline-secondary">Cargar más</a>
                    </div>
                </div>
            </div>
            <!-- Historial -->
    
            <div class="section inset mb-4 mt-4">
                <div class="accordion" id="accordionExample1">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#accordion01">
                                ¿Qué es {{ record.name }}?
                            </button>
                        </h2>
                        <div id="accordion01" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                            <div class="accordion-body">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at feugiat tellus. Sed quis
                                velit tellus.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#accordion02">
                                ¿Cómo funciona {{ record.name }}?
                            </button>
                        </h2>
                        <div id="accordion02" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                            <div class="accordion-body">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at feugiat tellus. Sed quis
                                velit tellus.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#accordion03">
                                ¿Cómo puedo comprar/vender {{ record.symbol }}?
                            </button>
                        </h2>
                        <div id="accordion03" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                            <div class="accordion-body">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at feugiat tellus. Sed quis
                                velit tellus.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <app-footer />
    
        </div>
        <!-- * Cápsula de la aplicación -->
    </template>

    <app-menu-bottom />
</template>

<script>
import ApexCharts from 'apexcharts';
import { getAsset } from '@/utils/web3';

export default {
    data() {
        return {
            record: null,
        };
    },
    computed: {
        isTokenOfficial() {
            return this.record.owner == process.env.VUE_APP_SOMBRERO_CONTRACT_FACTORY;
        }
    },
    async mounted() {
        await this.getAsset()
        this.runScripts()
    },
    methods: {
        async getAsset() {
            let address = this.$route.params.address
            console.log('address', address)
            try {
                const asset = await getAsset(address, this.$root.user.wallet.address);
                console.log('asset', asset)
                this.record = asset;
            } catch (error) {
                console.error('Error fetching assets:', error);
            }
        },
        runScripts() {
            // Multiple Carousel
            document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
                perPage: 4,
                rewind: true,
                type: "loop",
                gap: 16,
                padding: 16,
                arrows: false,
                pagination: false,
                breakpoints: {
                    768: {
                        perPage: 2
                    },
                    991: {
                        perPage: 3
                    }
                }
            }).mount());
            
            var chartExample1 = {
                series: [{
                    data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 1600, 1400, 1600, 2000, 1100, 501, 3000, 1000, 2000]
                }],
                chart: {
                    type: 'area',
                    width: '100%',
                    height: 140,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 2,
                },
                colors: ['#1DCC70'],
                tooltip: {
                    enabled: false
                }
            };

            var chartExample2 = {
                series: [{
                    data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 1700, 1600, 1400, 1600]
                }],
                chart: {
                    type: 'area',
                    width: '100%',
                    height: 140,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 2,
                },
                colors: ['#1DCC70'],
                tooltip: {
                    enabled: false
                }
            };

            var chartExample3 = {
                series: [{
                    data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 405, 666, 1090, 1309, 1400, 1500]
                }],
                chart: {
                    type: 'area',
                    width: '100%',
                    height: 140,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 2,
                },
                colors: ['#1DCC70'],
                tooltip: {
                    enabled: false
                }
            };

            var chartExample4 = {
                series: [{
                    data: [512, 405, 666, 1090, 1309, 1400, 1500, 700, 1000, 2000, 512, 405, 666, 1090, 1309, 1400, 1500]
                }],
                chart: {
                    type: 'area',
                    width: '100%',
                    height: 140,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 2,
                },
                colors: ['#1DCC70'],
                tooltip: {
                    enabled: false
                }
            };

            var chartExample5 = {
                series: [{
                    data: [512, 405, 666, 1090, 1309, 1400, 1309, 1400, 1500]
                }],
                chart: {
                    type: 'area',
                    width: '100%',
                    height: 140,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 2,
                },
                colors: ['#1DCC70'],
                tooltip: {
                    enabled: false
                }
            };

            // document.addEventListener('DOMContentLoaded', function () {
                document.querySelectorAll('.chart-example-1').forEach(chart => new ApexCharts(chart, chartExample1).render());
                document.querySelectorAll('.chart-example-2').forEach(chart => new ApexCharts(chart, chartExample2).render());
                document.querySelectorAll('.chart-example-3').forEach(chart => new ApexCharts(chart, chartExample3).render());
                document.querySelectorAll('.chart-example-4').forEach(chart => new ApexCharts(chart, chartExample4).render());
                document.querySelectorAll('.chart-example-5').forEach(chart => new ApexCharts(chart, chartExample5).render());
            // })

        },
    }
}
</script>