<template>
    <div class="section mt-4">
        <div class="section-heading">
            <h2 class="title">Transacciones</h2>
            <a href="/crypto/transactions" class="link">Ver todas</a>
        </div>
        <div class="card">
            <crypto-transactions-list :address="$root.user.wallet.address" />
        </div>
    </div>
</template>

<script>
import { ethers } from 'ethers'
import { getLatestBlock } from "@/utils/web3"

export default {
    props: {
        size: {
            type: Number,
            default: 4,
        },
        address: {
            type: String,
            default: '0x0',
        }
    },
    data() {
        return {
            records: []
        };
    },
    async mounted() {
    },
    methods: {
    }
}
</script>