<template>
    <!-- App Header -->
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Verificacion KYC
        </div>
        <div class="right">
            <!-- <a href="crypto-exchange.html" class="headerButton">
                <ion-icon name="add"></ion-icon>
            </a> -->
        </div>
    </div>

    <!-- App Capsule -->
    <div id="appCapsule">
        
        <!-- Wallet Card -->
        <div class="section pt-2">
            <div class="card">
                <div class="card-body">
                    <form-verify-kyc />
                </div>
            </div>
        </div>
        <!-- Wallet Card -->
    </div>
</template>