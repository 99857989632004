<template>
    <div class="appHeader">
        <div class="left">
            <a href="/" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Transacciones Activos
        </div>
        <div class="right">
            <a @click="getTransactions" class="headerButton">
                <ion-icon name="refresh-outline"></ion-icon>
            </a>
        </div>
    </div>

    <div id="appCapsule">
        <div class="section mt-2">
            <!-- <div class="section-title">Today</div> -->
            <div class="card">
                <crypto-transactions-list :address="$root.user.wallet.address" size="50" />
            </div>
        </div>
    </div>
</template>