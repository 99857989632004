<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader bg-primary text-light">
        <div class="left">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                <!-- <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged rounded w32"> -->
                <!-- <img :src="this.$root.user.avatar" alt="image" class="imaged rounded w32"> -->
                <div v-html="this.$root.user.avatar" class="imaged rounded w32 bg-white"></div>
                <!-- <span class="badge badge-danger">10</span> -->
            </a>
        </div>
        <div class="pageTitle">
            {{ $root.site.name.regular }}
        </div>
        <div class="right">
            <!-- <a href="app-notifications.html" class="headerButton">
                <ion-icon class="icon" name="person-outline"></ion-icon>
                <span class="badge badge-danger">8</span>
            </a> -->
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">
        <section-wallet />
        <section-portfolio :assets="$root.assets" maxl="5" />
        <!-- <section-watchlist /> -->
        <section-transactions />
        <section-promo />
        <!-- <section-news /> -->
        <br />
        <app-footer />
    </div>
    <!-- * App Capsule -->

    <app-menu-bottom />

    <app-sidebar />

</template>

<script>

export default {
    name: 'CryptoHomeView',
    created() {
    },
    mounted() {
        this.runCarousel()
    },
    methods: {
        runCarousel() {
            // Single Carousel
            document.querySelectorAll('.carousel-single').forEach(carousel => new Splide(carousel, {
                perPage: 3,
                rewind: true,
                type: "loop",
                gap: 16,
                padding: 16,
                arrows: false,
                pagination: false,
                breakpoints: {
                    768: {
                        perPage: 1
                    },
                    991: {
                        perPage: 2
                    }
                }
            }).mount());
            // Multiple Carousel
            document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
                perPage: 4,
                rewind: true,
                type: "loop",
                gap: 16,
                padding: 16,
                arrows: false,
                pagination: false,
                breakpoints: {
                    768: {
                        perPage: 2
                    },
                    991: {
                        perPage: 3
                    }
                }
            }).mount());
        }
    },
}
</script>