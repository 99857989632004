<template>
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Mi Portafolio
        </div>
        <div class="right">
            <a href="/swap" class="headerButton">
                <ion-icon name="add"></ion-icon>
            </a>
        </div>
    </div>
    <div id="appCapsule">
        <div class="section mt-2">
            <div class="section-title">Criptomonedas Principales</div>
            <div class="card">
                <!-- <section-portfolio :assets="$root.assets" maxl="5" /> -->
                <portfolio-list :assets="$root.assets" maxl="100" />
            </div>
        </div>
    </div>
    <br />
    <app-menu-bottom />
</template>