<template>

    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
        <a href="/" class="item active">
            <div class="col">
                <ion-icon name="home-outline"></ion-icon>
                <strong>Vista General</strong>
            </div>
        </a>
        <a href="/crypto/portfolio" class="item">
            <div class="col">
                <ion-icon name="pulse-outline"></ion-icon>
                <strong>Mis activos</strong>
            </div>
        </a>
        <a href="/deposit" class="item">
            <div class="col">
                <div class="action-button large">
                    <ion-icon name="logo-usd"></ion-icon>
                </div>
            </div>
        </a>
        <!-- <a href="#" class="item">
            <div class="col">
                <div class="action-button large">
                    <ion-icon name="logo-bitcoin"></ion-icon>
                </div>
            </div>
        </a>
        -->
        <a href="/swap" class="item">
            <div class="col">
                <ion-icon name="swap-horizontal"></ion-icon>
                <strong>Swap</strong>
            </div>
        </a>
        <!-- <a href="#" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a> -->
        <a href="/settings" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * App Bottom Menu -->

</template>

<script>
export default {
  name: 'AppBottomMenu',
  mounted() {
  },
}
</script>
